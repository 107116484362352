<template>
  <modal v-loading="loaders.location" @close="cancelForm" :self-down-close="false"
         :show.sync="$store.getters['LocationModule/getAddEditModalToggle']">
    <div slot="header">
      <h3>{{ headingText }} Location</h3>
    </div>
    <validation-observer ref="addEditLocationForm" v-slot="{handleSubmit}">
      <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">
        <base-input
          name="Title"
          label="Title*"
          v-model="request.location.name"
          :rules="{ custom_required: true }"
          placeholder="Title"/>
        <base-input
          name="Location"
          label="Location*"
          v-model="request.location.address"
          :rules="{ custom_required: true }"
          placeholder="Location"/>
        <base-select
          name="Status"
          label="Status*"
          v-model="request.location.status"
          :options="dropdownsOptions.status"/>

        <div class="float-right mt-4">
          <base-button type="secondary" class="ml-auto" @click="cancelForm">
            Cancel
          </base-button>
          <base-button native-type="submit" type="submit" class="btn-primary-action">
            {{ buttonText }}
          </base-button>
        </div>
      </form>
    </validation-observer>
  </modal>
</template>

<script>
import {constants} from "@/util/constants";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "AddEditLocation",
  props: {
    headingText: {
      type: String,
      default: 'Add',
      description: 'Heading text'
    },
    buttonText: {
      type: String,
      default: 'Save',
      description: 'Button text'
    },
    editId: {
      type: Number,
      default: 0,
      description: 'Edit ID for Location'
    }
  },
  data() {
    return {
      loaders: {
        location: false
      },
      request: {
        location: {...this.$store.getters['LocationModule/getLocation']}
      },
      dropdownsOptions: {
        status: constants.common.statusOptions,
      },
    }
  },
  watch: {
    editId(newVal) {
      if (newVal != 0) {
        this.getLocation()
      } else {
        this.resetForm()
      }
    },
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    /**
     * Handle Cancel form action
     */
    cancelForm() {
      this.resetForm()
      this.$emit('resetEditId')
      this.$store.commit('LocationModule/toggleAddEditModal', false)
    },

    /**
     * Reset Add/Edit Location Form
     */
    resetForm() {
      this.request.location = {...this.$store.getters['LocationModule/getLocation']}
      this.$refs.addEditLocationForm.reset()
    },

    /**
     * Redirect router and emit the relevant function
     * @param {string} formName - Form name
     */
    async submitForm(formName) {
      let vm = this
      const isLocationFormValid = await this.$refs['addEditLocationForm'].validate()
      if (isLocationFormValid) {
        if (vm.request.location.hasOwnProperty('id') && vm.request.location.id != 0) {
          vm.updateLocation()
        } else {
          vm.storeLocation()
        }
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        })
      }
    },

    /**
     * Get Requested Location
     */
    getLocation() {
      let vm = this
      vm.loaders.location = true
      vm.$store.dispatch('LocationModule/_getRequestedLocation', {id: vm.editId})
        .then(response => {
          vm.request.location = {...response.data.data}
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Location',
            message: message
          })
          vm.cancelForm()
        })
        .finally(() => {
          vm.loaders.location = false
        })
    },

    /**
     * Store Location
     */
    storeLocation() {
      let vm = this
      vm.loaders.location = true
      vm.$store.dispatch('LocationModule/_storeLocation', {formData: vm.request.location})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Location Created Successfully!'
          })
          vm.cancelForm()
          this.$emit('getLocations')
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Location',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loaders.location = false
        })
    },

    /**
     * Update Location
     */
    updateLocation() {
      let vm = this
      vm.loaders.location = true
      vm.$store.dispatch('LocationModule/_updateLocation', {formData: vm.request.location})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Location Updated Successfully!'
          })
          vm.cancelForm()
          this.$emit('getLocations')
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Location',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loaders.location = false
        })
    },
  }
}
</script>

<style scoped>

</style>
