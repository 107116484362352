<template>
  <div class="m-0 p-3">
    <add-edit-location :edit-id="addEditLocationForm.editId" :heading-text="addEditLocationForm.headingText"
                       :button-text="addEditLocationForm.buttonText" @getLocations="fetchLocations"
                       @resetEditId="addEditLocationForm.editId = 0"/>
    <div>
      <back-button/>
    </div>
    <div class="mb-3 d-flex justify-content-between align-items-center">
      <h1 class="m-0">List of Locations</h1>
      <base-button class="btn-secondary-action button-font title-btn-padding" @click="showAddModal">
        Add New
      </base-button>
    </div>
    <div class="mt-2 cardStyle" v-loading="loaders.checkboxButton || loaders.locations">
      <div class="row mx-0 justify-content-between align-items-center">
        <div class="mt-4 mb-1 ml-4">
          <base-button v-if="selectedRows.length"
                       class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                       @click="promptMultiRemove"
                       :disabled="loaders.removeModal"
          >Delete
          </base-button>
          <base-button v-if="selectedRows.length"
                       class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                       @click="handleActivate"
                       :disabled="loaders.checkboxButton"
          >Activate
          </base-button>
          <base-button v-if="selectedRows.length"
                       class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                       @click="handleDeactivate"
                       :disabled="loaders.checkboxButton"
          >Deactivate
          </base-button>
        </div>
      </div>
      <el-table
        header-row-class-name="thead-light"
        class="show-hidden-scrollbar event-list-table w-100"
        :data="response.locations.data"
        @selection-change="eventsMultiSelect">
        <el-table-column prop="selected" type="selection" :min-width="10">
        </el-table-column>
        <el-table-column label="TITLE" prop="name" :min-width="20">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="LOCATION" prop="address" :min-width="47">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.address }}</div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" :min-width="13">
          <template slot-scope="scope">
              <span :class="['status',getStatusClass(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatusClass(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" :min-width="10">
          <div slot-scope="{ $index, row }" class="d-flex">
            <el-tooltip content="Edit Location" placement="top">
              <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" @click="showEditModal(row.id)" alt="">
            </el-tooltip>
            <el-tooltip content="Delete Location" placement="top">
              <img
                class="pointer"
                @click="promptRemove(row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
      <div class="pt-5 w-100 d-flex justify-content-end">
        <custom-pagination
          class="pr-5"
          :total="response.locations.total"
          v-model="response.locations.current_page"
          :per-page="response.locations.per_page"
          @input="changePage"
        >
        </custom-pagination>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeLocation"
        @onRemoveMultiple="removeLocations"
      />
    </div>
  </div>
</template>

<script>
import CustomPagination from "@/views/Components/Pagination/CustomPagination";
import {BasePagination} from "@/components";
import {Option, Select, Table, TableColumn} from "element-ui";
import TableWithActionsAndPagination from "@/components/Tables/TableWithActionsAndPagination";
import BackButton from "@/components/Router/BackButton";
import AddEditLocation from "@/views/Pages/SuperAdmin/Event/Location/AddEditLocation";

const RemoveModal = () => import("@/components/Modals/RemoveModal.vue");

export default {
  name: "Locations",
  components: {
    CustomPagination,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    CategoryTemplate: TableWithActionsAndPagination,
    BackButton,
    AddEditLocation,
    RemoveModal,
  },
  data() {
    return {
      loaders: {
        locations: false,
        removeModal: false,
        checkboxButton: false,
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      params: {
        page: 1
      },
      timezoneOptions: [
        {value: null, label: 'Select Timezone'},
        {value: 'America/New_York', label: 'Eastern Daylight Time New York (GMT-4)'},
        {value: 'America/Chicago', label: 'Central Daylight Time Chicago (GMT-5)'},
        {value: 'America/Denver', label: 'Mountain Daylight Time Denver (GMT-6)'},
        {value: 'America/Phoenix', label: 'Mountain Standard Time Phoenix (GMT-7)'},
        {value: 'America/Los_Angeles', label: 'Pacific Daylight Time Los Angeles (GMT-7)'},
        {value: 'America/Anchorage', label: 'Alaska Daylight Time Anchorage (GMT-8)'},
        {value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Standard Time (GMT-10)'},
        {value: 'Australia/Sydney', label: 'Australian Eastern Standard Time Sydney (GMT+10)'},
      ],
      response: {
        locations: {
          data: [],
          current_page: 1,
          per_page: 10,
          total: 0,
        },
        timezone: ''
      },
      addEditLocationForm: {
        loader: false,
        headingText: 'Add',
        buttonText: 'Save',
        editId: 0
      },
    }
  },
  computed: {
    /**
     * Extract selected ids
     * @returns {*[]}
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  },
  mounted() {
    this.fetchLocations()
  },
  methods: {
    /**
     * Update program timezone
     */
    updateTimezone() {
      let vm = this
      vm.loaders.locations = true
      const payload = {
        timezone: vm.response.timezone
      }
      vm.$store.dispatch('LocationModule/_updateTimezone', payload)
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Timezone',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.locations = false
        })
    },

    /**
     * Show Add Location Modal
     */
    showAddModal() {
      this.addEditLocationForm.editId = 0
      this.addEditLocationForm.buttonText = 'Save'
      this.addEditLocationForm.headingText = 'Add'
      this.$store.commit('LocationModule/toggleAddEditModal', true)
    },

    /**
     * Show Edit Location Modal
     * @param id
     */
    showEditModal(id) {
      this.addEditLocationForm.editId = id
      this.addEditLocationForm.buttonText = 'Update'
      this.addEditLocationForm.headingText = 'Edit'
      this.$store.commit('LocationModule/toggleAddEditModal', true)
    },

    /**
     * Handle Active Button event
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handle DeActive Button event
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Prompt remove
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this Location.'
      this.removeId = row.id
    },

    /**
     * Prompt Multi-remove
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove these Locations.'
      this.removeId = this.extractSelectedIds
    },

    /**
     * Handle multi-select action
     * @param selectedRows
     */
    eventsMultiSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'Inactive-Status' : 'Active-Status'
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Change Page
     * @param page
     */
    changePage(page) {
      this.params.page = page
      this.fetchLocations()
    },

    /**
     * Get Locations Listing
     */
    fetchLocations() {
      let vm = this
      vm.loaders.locations = true
      const params = {
        params: Object.assign({}, this.params)
      }
      vm.$store.dispatch('LocationModule/_getLocations', params)
        .then(response => {
          vm.response.locations = response.data.data.locations
          vm.response.timezone = response.data.data.timezone
        })
        .catch(error => {
            if (!axios.isCancel(error)) {
              const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
              vm.$notify.error({
                title: 'Locations',
                message: message
              })
            }
        })
        .finally(() => {
          vm.loaders.locations = false
        })
    },

    /**
     * Remove Location
     * @param id
     */
    removeLocation(id) {
      this.deleteLocation(id)
    },

    /**
     * Remove Multiple Location
     */
    removeLocations() {
      this.deleteLocation(this.removeId)
    },

    /**
     * Delete Event(s)
     * @param data
     */
    deleteLocation(data) {
      let vm = this
      vm.loaders.removeModal = true

      const payload = {
        ids: Array.isArray(data) ? data : [data],
      }

      vm.$store.dispatch('LocationModule/_deleteLocation', payload)
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Location(s) Deleted Successfully!'
          })
          vm.removeId = []
          vm.fetchLocations()
          vm.$store.commit("hideRemoveModal")
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Location',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.removeModal = false
        })
    },

    /**
     * Update Status of Location(s)
     * @param ids
     * @param status
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.checkboxButton = true

      const payload = {
        ids: Array.isArray(ids) ? ids : [ids],
        status: status
      }

      vm.$store.dispatch('LocationModule/_updateLocationStatus', payload)
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Location(s) Status Updated Successfully!'
          })
          vm.removeId = []
          vm.fetchLocations()
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Status',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.checkboxButton = false
        })
    },
  }
}
</script>

<style scoped>

</style>
