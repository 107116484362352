<template>
  <div>
    <img
      class="pointer mr-2"
      @click="handleAction"
      :src="src"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "Actions",
  props: ['action', 'src'],
  methods: {
    handleAction() {
      if (this.action === 'show') {
        this.$emit('handleShow')
      } else if (this.action === 'copy') {
        this.$emit('handleCopy')
      } else if (this.action === 'edit') {
        this.$emit('handleEdit')
      } else if (this.action === 'delete') {
        this.$emit('handleDelete')
      }
    }
  }
}
</script>

<style scoped>

</style>
