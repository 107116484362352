<template>
  <div class="content">
    <div class="mt-3">
      <el-table
        :data="tableData"
        row-key="id"
        header-row-class-name="thead-light"
        @selection-change="onTableRowSelected"
      >
        <el-table-column
          prop="selected"
          align="center"
          type="selection" class="mt-3"
          class-name="checkbox-column mt-3">
        </el-table-column>
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          v-bind="column"
          :class-name="column.prop"
        >
          <template scope="scope">
            <span
              :class="[column.prop, getColumn(scope.row, column)]"
              @click="handleColumnClick(column.prop, scope.row)">
              {{ getColumn(scope.row, column) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column min-width="50px" align="right" label="Actions">
          <div slot-scope="{ $index, row }" class="d-flex">
            <div v-for="(action, index) in actions" :key="index">
              <actions
                class="pointer mr-2"
                @handleShow="handleShow($index, row)"
                @handleCopy="handleCopy($index, row)"
                @handleEdit="handleEdit($index, row)"
                @handleDelete="handleDelete($index, row)"
                :action="action.action"
                :src="action.src"
                alt=""
              />
            </div>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="col-12 d-flex justify-content-end flex-wrap">
      <custom-pagination
        class="mt-4 pagination-no-border float-right"
        :total="pagination.total"
        v-model="pagination.current_page"
        :per-page="pagination.per_page"
        @input="changePage"
      >
      </custom-pagination>
    </div>
  </div>
</template>
<script>
import {Table, TableColumn, Select, Option} from "element-ui";
import Actions from "@/components/Actions/Actions";
import CustomPagination from "@/views/Components/Pagination/CustomPagination.vue";

export default {
  components: {
    CustomPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Actions
  },
  props: ["tableData", "tableColumns", "pagination", "parentSearch", "actions"],
  data() {
    return {
      searchQuery: '',
    };
  },
  methods: {
    getColumn(row, column) {
      if (column.prop === 'default_template') {
        return this.defaultTemplate(row.default_template);
      } else if (column.prop === 'status') {
        return this.getStatus(row.status);
      }
      return row[column.prop];
    },
    defaultTemplate(defaultTemplate) {
      if (defaultTemplate == 0) {
        return 'Make Default'
      }
      return 'Default Template';
    },
    getStatus(status) {
      if (status == 0) {
        return 'Inactive'
      }
      return 'Active';
    },
    onTableRowSelected(rows) {
      this.$emit('onTableRowSelected', rows);
    },
    handleColumnClick(prop, row) {
      this.$emit('columnClicked', {prop, row})
    },
    handleShow(index, row) {
      this.$emit('onShow', row);
    },
    handleCopy(index, row) {
      this.$emit('onCopy', row);
    },
    handleEdit(index, row) {
      this.$emit('onEdit', row);
    },
    handleDelete(index, row) {
      this.$emit('onRemove', row);
    },
    changePage(page) {
      this.$emit("changed-page", page);
    },
  },
  mounted() {
    this.searchQuery = this.parentSearch;
  },
};
</script>
<style scoped>
.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.status .Active:before, .status .Inactive:before {
  content: '';
  position: absolute;
  left: -15px !important;
  top: 5px !important;
  height: 7px;
  width: 7px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

.default_template.Default.Template {
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 1px;
  color: #832C44;
}

.default_template.Make.Default {
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 1px;
  cursor: pointer;
  color: #172B4D;
}

.default_template.Make.Default:hover {
  text-decoration: underline;
}


</style>
<style>
.no-border-card .card-footer {
  border-top: 0;
}

.checkbox-column .el-checkbox .el-checkbox__input .el-checkbox__inner {
  width: 16px;
  height: 16px;
  font-size: 16px;
  border-radius: 3px;
  margin-top: 11px;
}

.default_template {
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 1px;
  color: #172B4D;
}

.status {
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 1px;
  color: #525F7F;
}
</style>
